
@font-face {
    font-family: 'Great Vibes';
    font-style: normal;
    font-weight: 400;
    src: local('Great Vibes'), local('GreatVibes-Regular'), url(http://fonts.gstatic.com/s/greatvibes/v4/6q1c0ofG6NKsEhAc2eh-3afTCPadK0KLfdEfFtGWCYw.woff) format('woff');
}
h1,h2,h3,h4,h5,h6{ font-family: 'Oswald','Raleway',"Helvetica Neue", Helvetica, Arial, sans-serif; }

body, html{
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  overflow-y: auto;
  background-color: #333333;
}
a {
  cursor: pointer;
}
#home {
  min-height: 480px;
}

/*#home section {
  height: 745px;
}
#aboutUs section {
  height: 518px;
}*/
.btn-scroll-down {
  width: 100px;
  height: 50px;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
  -webkit-transition: display 0.5s easy-in-out;
  -moz-transition: display 0.5s easy-in-out;
  -o-transition: display 0.5s easy-in-out;
  -ms-transition: display 0.5s easy-in-out;
  transition: display 0.5s easy-in-out;
}
.btn-scroll-down:before,
.btn-scroll-down:after {
  content: '';
  display: block;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
}
.btn-scroll-down:before {
  bottom: 20px;
  width: 60px;
  height: 60px;
}
.btn-scroll-down:after {
  width: 30px;
  height: 30px;
  bottom: 35px;
  -webkit-animation: sc-down-a 0.3s infinite alternate;
  -moz-animation: sc-down-a 0.3s infinite alternate;
  -o-animation: sc-down-a 0.3s infinite alternate;
  -ms-animation: sc-down-a 0.3s infinite alternate;
  animation: sc-down-a 0.3s infinite alternate;
}

.border {
  border: 2px solid #292929;
}

h1.xlarge {
  font-size: 120px;
  line-height: 150px;
  font-weight: 170;
}

.orange {
  color: #ff6633;
}
.violet {
  color: #996666;
}
.grey {
  color: #333333;
}
.lgrey {
  color: #666666;
}

.orange_bg {
  background-color: #ff6633;
}
.violet_bg {
  background-color: #996666;
}
.grey_bg {
  background-color: #333333;
}
.lgrey_bg {
  background-color: #666666;
}


#offers figure {
  cursor: pointer;
}
.o-6 {
  background-color: lightgrey;
  opacity: 0.6;
}
.o-5 {
  background-color: black;
  opacity: 0.5;
}
.o-1 {
  opacity: 1;
}
.strong h3 {
  opacity: none;
  color: black;
}
.strong p {
  opacity: 1;
  color: black;
}

/*PHOTO GRID*/
.photo-grid {
	margin: 1em auto;
	max-width: 1106px;
	text-align: center;
}
.photo-grid li {
  /*border: 2px solid white;*/
	display: inline-block;
	margin: 1em;
	width: 289px;
}
.photo-grid img {
	display: block;
	height: auto;
	max-width: 100%;
}
.photo-grid figure {
	height: 163px;
	overflow: hidden;
	position: relative;
}
.photo-grid figcaption {
	background: rgba(0,0,0,0.8);
	color: white;
	display: table;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100;
}
.photo-grid figcaption div  {
	display: table-cell;
	font-size: 1.5em;
	position: relative;
	/*top: -40px;*/
	width: 289px;
	vertical-align: middle;
}
.photo-grid li:hover figcaption {
	opacity: 1;
}
.photo-grid figure > img {
	display: block;
	height: auto;
	-webkit-transition: all 300ms;
	-moz-transition: all 300ms;
	transition: all 300ms;
	max-width: 100%;
}
.photo-grid figure > li:hover img {
	-webkit-transform: scale(1.4);
	-moz-transform: scale(1.4);
	transform: scale(1.4);
}
.photo-grid figcaption {
	background: rgba(0,0,0,0.8);
	color: white;
	display: table;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	-webkit-transition: all 300ms;
	-moz-transition: all 300ms;
	transition: all 300ms;
	-webkit-transition-delay: 100ms;
	-moz-transition-delay: 100ms;
	transition-delay: 100ms;
	z-index: 100;
}
.nopadding {
  padding: 0;
  margin-top: -1px;
}
h2 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 20px;
}
p {
  font-size: 1.2em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  margin-bottom: 15px;
  font-weight: 400;
}
#portfolio{
  max-width: 1400px;
  z-index: 1;
  margin: 0px auto;
}
#portfolio .small-box {
  // height: 260px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
}
#portfolio .large-box {
  // height: 520px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
}

/* Portfolio Small Box - Hover Effects
===========================================*/
.btn:active, .btn.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px transparent !important;
    box-shadow: inset 0 3px 5px transparent !important;
}

.small-box .hover-bg{
    position: relative;
}
.small-box .hover-text{
    display: none;
    position: absolute;
    top: 0;
    padding: 40px 40px;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
}

.small-box .hover-text.on{
    display: block;
}

.small-box:hover .hover-text.animated{
    display: block;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    100% {
        opacity: 0.8;
        -webkit-transform: translateY();
        transform: translateY();
    }
}
.small-box .hover-text .ptitle {
    border: 1px solid #0c1026;
    padding: 2px 5px;
    width: 100%;
    font-size: 100%;
}
.hover-text h2 {
    font-size: 100%;
    font-family: 'Great Vibes' cursive;
    text-transform: none;
    letter-spacing: 0;
    font-size: 30px;
    text-align: center;
}
.small-box a { color: #0c1026 !important }
p.excerpt {
    padding-top: 20px;
    font-size: 13px;
}
.small-box .hover-text {
    background: #ffffff;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    color: #0c1026;
}
.small-box a.btn.read-more-btn,
a.btn.btn2 {
    background: transparent;
    color: #0b102c !important;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: none !important;
    padding: 6px 0px;
}
.small-box .hover-text a.btn.read-more-btn span.fa,
a.btn.btn2 span.fa,
a.btn { margin-right: 20px }
/* Portfolio Large Box - Hover Effects
===========================================*/
.large-box .hover-bg { position: relative }
.large-box .hover-text {
    display: none;
    position: absolute;
    top: 0;
    padding: 40px 40px;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
}
.large-box:hover .hover-text.animated {
    display: block;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY();
        transform: translateY();
    }
}
.large-box .hover-text.on { display: block }
.large-box .hover-text {
    background: #ffffff;
    opacity: 0.8;
    color: #0c1026;
    width: 100%;
    height: 100%;
    // min-height: 560px;

}
.large-box a { color: #0c1026 }
.large-box .hover-text .ptitle {
    border: 1px solid #0c1026;
    padding: 2px 5px;
    width: 100%;
    font-size: 100%;
}

.large-box .hover-text .client-logo img {
    width: 120px;
    text-align: center;
}
.large-box .hover-text a.btn.read-more-btn {
    /*position: absolute;
    top: 500px;*/
    /*background: transparent;*/
    color: #0c1026 !important;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    /*border: none !important;*/
}
.large-box .btn.read-more-btn {
    background: transparent;
    color: #0b102c !important;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: none !important;
    padding: 0px 0px;
}

.large-box .hover-text a.btn.read-more-btn span.fa { margin-right: 20px }

.small-box img.img-responsive {
  width: calc(100% + 80px);
  max-width: 120%;
  -webkit-backface-visibility: hidden;
  //transform: translateX(-20px) translateY(-20px);
  //-webkit-transform: translateX(-20px) translateY(-20px);
  //-moz-transform: translateX(-20px) translateY(-20px);
  //-ms-transform: translateX(-20px) translateY(-20px);
  //-o-transform: translateX(-20px) translateY(-20px);
}

.small-box a {
  color: #0c1026 !important;
}

p.excerpt {
  padding-top: 20px;
  font-style: 13px;
}
.small-box .btn.read-more-btn{
  background: transparent;
  color: #0b102c !important;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none !important;
  padding: 6px 0px;
}
.modal h4 {
  font-family: 'Great Vibes' ;
  font-size: 3em;

}
.semicircle {

}
.weiter-button {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 42.67%;
  height: 105px;
  width: 16.66%;
  padding: 20px;
  border-radius: 105px 105px 0 0;
  background: #996666;
  z-index: 2;
  margin: auto;
  font-size: 1.2em;
}
.weiter-button p {
  color: #ffffff;
  margin-top: 10px;
  font-family: 'Great Vibes' ;
  font-size: 1.5em;
  margin-bottom: 5px;
}
.weiter-button a {
  color: #ffffff;
}
.product-button {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 42.67%;
    height: 105px;
    min-width: 100px;
    width: 16.66%;
    padding: 20px;
    border-radius: 105px 105px 0 0;
    background: #996666;
    z-index: 2;
    margin: auto;
    font-size: 1.2em;
}
.product-button p {
  color: #ffffff;
  margin-top: 10px;
  font-family: 'Great Vibes' ;
  font-size: 1.5em;
  margin-bottom: 5px;
}
.product-button a {
  color: #ffffff;
}
.invitation-button {
    text-align: center;
    padding: 10px;
    left: 10%;
    top: 10%;
    background: #996666;
    color: #ffffff;

    z-index: 2;

  	border-radius: 50%;
  	height: 10em;
  	width: 10em;
    font-size: 1.2em;
}
.invitation-button p {
  margin-top: 20px;
  font-family: 'Great Vibes' ;
  font-size: 1.7em;
}
.invitation-button a {
  color: #ffffff;
}

#note {
  background-color: #ffffff;
}
#note h4 {
  color: #333333;
  font-family: 'Great Vibes';
  font-size: 3em;
}
#note .col-xs-4 {
  padding-left: 0px;
  padding-right: 0px;
}
#notesmall {
  background-color: #ffffff;
}
#notesmall h4 {
  color: #333333;
  font-family: 'Great Vibes';
  font-size: 3em;
}
#notesmall .col-xs-4 {
  padding-left: 0px;
  padding-right: 0px;
}

#grid[data-columns]::before {
	content: '3 .column.size-1of3';
}
.column { float: left; }
.size-1of1 { width: 100%; }
.size-1of2 { width: 50%; }
.size-1of3 { width: 33.333%; }

#grid img {
  border: 2px solid white;
}
.hover-bg {
  cursor: pointer;
}

.service-item i {
    position: relative;
    /*top: 100px;*/
    display: inline-block;
    margin: 10px;
    padding: 10px;
    color: #d7b364;
    font-size: 48px;
}
.service-item {
    width: 100%;
    height: auto;
    margin: 10px 0;
    text-align: center;
    color: #d7b364;
}
.service-item h5 {
  color: #d7b364;
}

#services {
  background-color: #333333;
  color: lightgrey;
}
.carousel-control {
  color: #996666;
  margin: 15px;
}
#testimonials {
  background-color: #ffffff;
}
#testimonials .service-item h4 {
  color: #000000;
}
#testimonials .service-item p {
  font-style: italic;
}
#partner {
  background-color: #333333;
  margin: 0px auto;
}
#partner h4 {
  color: #ffffff;
}
#partner .fa {
  color: lightgrey;
  width: auto;
  height: auto;

  padding-top: 2%;
}
#partner img {
  height: auto;

  color: #fff;
}
#partner .row img {

}
footer {
  background-color: #333333;
  height: 40px;
}
#photo-gallery {
  max-width: 1400px;
  margin: 0px auto;
}

#shio {
  font-size: 18px;
}
.services {
  font-size: 1.2em;
}

.services li {
  padding-bottom: 15px;
}
#contact a:hover {
  color: #fff;
}




/*media queries*/
@media (max-width: 374) {
  .product-button {
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 29.17%;
      height: 65px;
      min-width: 50px;
      width: 41.66%;
      padding: 13px 0;
      border-radius: 105px 105px 0 0;
      background: #996666;
      z-index: 2;
      margin: auto;
  }
  .product-button p {
    color: #ffffff;
    margin-top: 12px;
    font-family: 'Great Vibes' ;
    font-size: 1.2em;
    margin-bottom: 0;
  }
  .product-button a {
    color: #ffffff;
  }
  .weiter-button {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 29.17%;
    height: 65px;
    min-width: 50px;
    width: 41.66%;
    padding: 18px;
    border-radius: 105px 105px 0 0;
    background: #996666;
    z-index: 2;
    margin: auto;
  }
  .weiter-button p {
    color: #ffffff;
    margin-top: 5px;
    font-family: 'Great Vibes' ;
    font-size: 1.2em;
    margin-bottom: 0px;
  }
  .weiter-button a {
    color: #ffffff;
  }
}
@media (max-width: 430px) {
  .product-button {
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 29.17%;
      height: 85px;
      min-width: 50px;
      width: 41.66%;
      padding: 13px 0;
      border-radius: 105px 105px 0 0;
      background: #996666;
      z-index: 2;
      margin: auto;
  }
  .product-button p {
    color: #ffffff;
    margin-top: 12px;
    font-family: 'Great Vibes' ;
    font-size: 1.2em;
    margin-bottom: 0;
  }
  .product-button a {
    color: #ffffff;
  }
  .weiter-button {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 29.17%;
    height: 85px;
    min-width: 50px;
    width: 41.66%;
    padding: 18px;
    border-radius: 105px 105px 0 0;
    background: #996666;
    z-index: 2;
    margin: auto;
  }
  .weiter-button p {
    color: #ffffff;
    margin-top: 5px;
    font-family: 'Great Vibes' ;
    font-size: 1.2em;
    margin-bottom: 0px;
  }
  .weiter-button a {
    color: #ffffff;
  }
}

@media (min-width: 431px) and (max-width: 500px){
  .product-button {
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 33.335%;
      height: 75px;
      width: 33.33%;
      padding: 18px;
      border-radius: 105px 105px 0 0;
      background: #996666;
      z-index: 2;
      margin: auto;
  }
  .product-button p {
    color: #ffffff;
    margin-top: 5px;
    font-family: 'Great Vibes' ;
    font-size: 1.3em;
    margin-bottom: 0px;
  }
  .product-button a {
    color: #ffffff;
  }
  .weiter-button {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 33.335%;
    height: 75px;
    width: 33.33%;
    padding: 18px;
    border-radius: 105px 105px 0 0;
    background: #996666;
    z-index: 2;
    margin: auto;
  }
  .weiter-button p {
    color: #ffffff;
    margin-top: 5px;
    font-family: 'Great Vibes' ;
    font-size: 1.3em;
    margin-bottom: 0px;
  }
  .weiter-button a {
    color: #ffffff;
  }
}
@media (min-width: 501px) and (max-width: 1023px){
  .product-button {
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 37.75%;
      height: 95px;
      width: 25%;
      padding: 18px;
      border-radius: 105px 105px 0 0;
      background: #996666;
      z-index: 2;
      margin: auto;
  }
  .product-button p {
    color: #ffffff;
    margin-top: 5px;
    font-family: 'Great Vibes' ;
    font-size: 1.3em;
    margin-bottom: 0px;
  }
  .product-button a {
    color: #ffffff;
  }
  .weiter-button {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 37.75%;
    height: 95px;
    width: 25%;
    padding: 18px;
    border-radius: 105px 105px 0 0;
    background: #996666;
    z-index: 2;
    margin: auto;
  }
  .weiter-button p {
    color: #ffffff;
    margin-top: 5px;
    font-family: 'Great Vibes' ;
    font-size: 1.3em;
    margin-bottom: 0px;
  }
  .weiter-button a {
    color: #ffffff;
  }
}
@media (min-width: 977px) {
  .product-button {
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 41.67%;
      height: 90px;
      width: 16.66%;
      padding: 18px;
      border-radius: 105px 105px 0 0;
      background: #996666;
      z-index: 2;
      margin: auto;
  }
  .product-button p {
    color: #ffffff;
    margin-top: 5px;
    font-family: 'Great Vibes' ;
    font-size: 1.3em;
    margin-bottom: 0px;
  }
  .product-button a {
    color: #ffffff;
  }
  .weiter-button {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 41.67%;
    height: 90px;
    width: 16.66%;
    padding: 18px;
    border-radius: 105px 105px 0 0;
    background: #996666;
    z-index: 2;
    margin: auto;
  }
  .weiter-button p {
    color: #ffffff;
    margin-top: 5px;
    font-family: 'Great Vibes' ;
    font-size: 1.3em;
    margin-bottom: 0px;
  }
  .weiter-button a {
    color: #ffffff;
  }
}
@media screen and (max-width: 480px){
	#grid[data-columns]::before {
		content: '1 .column.size-1of1';
	}
}

@media screen and (min-width: 481px) and (max-width: 768px) {
	#grid[data-columns]::before {
		content: '2 .column.size-1of2';
	}
}

@media screen and (min-width: 769px) {
	#grid[data-columns]::before {
		content: '3 .column.size-1of3';
	}
}
@media (min-width: 1400px) {
  #photo-gallery {
    margin-top: 100px;
  }
}

.text-center { text-align: center; }

.carousel-control.right, .carousel-control.left {
  background-image: none;
}

.well {
  background-color: transparent;
  border: none;
}
